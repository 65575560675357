export enum MODAL_KEYS {
  "playlist-details" = "playlist-details",
  player = "player",
  search = "search",
  "change-language" = "change-language",
  "create-playlist" = "create-playlist",
  "add-to-playlist" = "add-to-playlist",
  "playlists-list-modal" = "playlists-list-modal",
  "request-login" = "request-login",
  news = "news",
  "update-available" = "update-available",
}
