import { Firebase } from "@/services/firebase";
import { FirebaseUtils } from "@/utils/FirebaseUtils";
import {
  AuthenticateWithGoogleReponse,
  IAuthRepository,
  IsAuthenticatedReturnType,
} from "../interfaces/IAuthRepository";
import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";

export class FirebaseAuthRepository
  extends FirebaseUtils
  implements IAuthRepository
{
  constructor(private firebase: Firebase) {
    super();
  }

  async authenticateWithGoogle(): Promise<AuthenticateWithGoogleReponse> {
    const auth = this.firebase.auth();
    const provider = this.firebase.getGoogleProvider();

    try {
      const response = await signInWithPopup(auth, provider);

      return {
        authenticated: true,
        user: {
          id: response.user?.uid as string,
          email: response.user?.email as string,
        },
      };
    } catch (e) {
      const error = e as {
        code:
          | "auth/expired-action-code"
          | "auth/invalid-email"
          | "auth/user-disabled";
      };

      const codes: {
        [key in typeof error.code]: any;
      } = {
        "auth/user-disabled": "USER_DISABLED",
        "auth/expired-action-code": "EMAIL_BAD_FORMATTED",
        "auth/invalid-email": "EMAIL_BAD_FORMATTED",
      };

      return {
        error: codes[error.code],
        authenticated: false,
      };
    }
  }

  async isAuthenticated(): Promise<IsAuthenticatedReturnType> {
    return new Promise((resolve, reject) => {
      const auth = this.firebase.auth();
      onAuthStateChanged(auth, function (user) {
        if (user)
          return resolve({
            authenticated: true,
            user: {
              id: user.uid,
              email: user.email as string,
            },
          });
        return resolve({ authenticated: false });
      });
    });
  }

  async logout() {
    try {
      const auth = this.firebase.auth();
      signOut(auth);

      return { succeed: true };
    } catch (e) {
      return { succeed: true };
    }
  }
}
