import { PlausibleAnalyticsService } from "./src/services/plausible-analytics-service";

const transform_duration_in_message = (duration_in_ms: number) => {
  if (duration_in_ms > 60000) {
    return `>60s`;
  }
  if (duration_in_ms > 50000) {
    return `>50s`;
  }
  if (duration_in_ms > 40000) {
    return `>40s`;
  }
  if (duration_in_ms > 30000) {
    return `>30s`;
  }
  if (duration_in_ms > 20000) {
    return `>20s`;
  }
  if (duration_in_ms > 10000) {
    return `>10s`;
  }

  if (duration_in_ms > 9000) {
    return `>9s`;
  }

  if (duration_in_ms > 8000) {
    return `>8s`;
  }

  if (duration_in_ms > 7000) {
    return `>7s`;
  }

  if (duration_in_ms > 6000) {
    return `>6s`;
  }

  if (duration_in_ms > 5000) {
    return `>5s`;
  }

  if (duration_in_ms > 4000) {
    return `>4s`;
  }

  if (duration_in_ms > 3000) {
    return `>3s`;
  }

  if (duration_in_ms > 2000) {
    return `>2s`;
  }

  if (duration_in_ms > 1000) {
    return `>1s`;
  }

  if (duration_in_ms < 1000) {
    return `<1s`;
  }
};

export const catchAudioTiming = () => {
  const analytics_service = new PlausibleAnalyticsService();

  const perfObserver = new PerformanceObserver((list) => {
    list
      .getEntries()
      .filter((entry) => entry.entryType === "resource")
      .forEach((entry) => {
        const timing = entry as PerformanceResourceTiming;
        if (timing.initiatorType === "audio") {
          analytics_service.send({
            category: "audio",
            action: "loaded",
            data: {
              duration_in_human_readable: transform_duration_in_message(
                timing.duration
              ),
              audio_url: timing.name,
            },
          });
        }
      });
  });

  perfObserver.observe({ entryTypes: ["resource"] });
};
