import { tracks } from "@/database/tracks";
import * as types from "./types";
import { RootState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { navigate } from "@reach/router";
import { all_playlists } from "@/database/playlists";
import { addHash, removeHash } from "@/lib/utils";
import { MODAL_KEYS } from "@/constants/modal-keys";

export const player_set_playing = (
  payload: types.player_set_playing_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_playing,
  payload,
});

export const player_next = (): types.PlayerActionTypes => ({
  type: types.player_next,
});

export const player_add_track_to_queue = (
  payload: types.player_add_track_to_queue_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_add_track_to_queue,
  payload,
});

export const player_change_search_query = (
  payload: types.player_change_search_query_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_change_search_query,
  payload,
});

export const $player_change_search_query = (
  payload: types.player_change_search_query_action["payload"]
) => {
  return async (dispatch: any) => {
    dispatch(player_change_search_query(payload));
    dispatch($player_open_search_modal());
  };
};

export const player_previous = (): types.PlayerActionTypes => ({
  type: types.player_previous,
});

export const player_set_loading = (
  payload: types.player_set_loading_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_loading,
  payload,
});

export const player_toggle_replay_mode = (): types.PlayerActionTypes => ({
  type: types.player_toggle_replay_mode,
});

export const player_on_end = (): types.PlayerActionTypes => ({
  type: types.player_on_end,
});

export const player_change_track_time = (
  payload: types.player_change_track_time_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_change_track_time,
  payload,
});

export const player_set_slider_track_time = (
  payload: types.player_set_slider_track_time_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_slider_track_time,
  payload,
});

export const player_toggle_random_tracks = (): types.PlayerActionTypes => ({
  type: types.player_toggle_random_tracks,
});

export const player_toggle_mute = (): types.PlayerActionTypes => ({
  type: types.player_toggle_mute,
});

export const player_set_tracks = (
  payload: types.player_set_tracks_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_tracks,
  payload,
});

export const player_set_mobile_player_open = (
  payload: types.player_set_mobile_player_open_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_mobile_player_open,
  payload,
});

export const $player_set_mobile_player_open = (
  payload: types.player_set_mobile_player_open_action["payload"]
) => {
  return async (dispatch: any, getState: () => RootState) => {
    dispatch(player_set_mobile_player_open(payload));

    if (payload.is_open) {
      navigate(
        addHash({ path: MODAL_KEYS.player, currentHash: window.location.hash })
      );
    } else {
      navigate(
        removeHash({
          path: MODAL_KEYS.player,
          currentHash: window.location.hash,
        })
      );
    }
  };
};

export const player_set_volume = (
  payload: types.player_set_volume_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_volume,
  payload,
});

export const player_set_track_time = (
  payload: types.player_set_track_time_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_track_time,
  payload,
});

export const player_set_playing_track = (
  payload: types.player_set_playing_track_action["payload"]
): types.PlayerActionTypes => ({
  type: types.player_set_playing_track,
  payload,
});

export const $player_set_playing_track = (
  payload: types.player_set_playing_track_action["payload"]
) => {
  return async (dispatch: any, getState: () => RootState) => {
    dispatch(player_set_playing_track(payload));
    dispatch($player_set_mobile_player_open({ is_open: true }));
  };
};

export const player_close_playlist_details_pane =
  (): types.PlayerActionTypes => ({
    type: types.player_close_playlist_details_pane,
  });

export const $player_close_playlist_details_pane = () => {
  return async (dispatch: any) => {
    const hash = window.location.hash;
    const removedHash = removeHash({
      path: MODAL_KEYS["playlist-details"],
      currentHash: hash,
    });

    navigate(removedHash);
  };
};

export const $player_close_search_modal = () => {
  return async (dispatch: any) => {
    navigate(
      removeHash({ path: MODAL_KEYS.search, currentHash: window.location.hash })
    );
  };
};

export const $player_open_search_modal = () => {
  return async (dispatch: any) => {
    navigate(
      addHash({ path: MODAL_KEYS.search, currentHash: window.location.hash })
    );
  };
};

export const $player_set_current_track_from_queue = (payload: {
  title_id: string;
}) => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, types.PlayerActionTypes>,
    getState: () => RootState
  ) => {
    const { player } = getState();

    const track = tracks.find((track) => track.title_id === payload.title_id);

    if (!track) return;

    if (payload.title_id === player.current_track?.title_id) {
      return dispatch(player_set_playing({}));
    }

    const track_index = player.tracks_currently_playing.findIndex(
      (track) => track.title_id === payload.title_id
    );

    if (track_index !== -1) {
      const track = player.tracks_currently_playing[track_index];

      dispatch($player_set_playing_track({ track }));
    } else {
      dispatch(player_set_tracks({ tracks: [track] }));
      dispatch($player_set_playing_track({ track }));
    }
  };
};
