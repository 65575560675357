import * as types from "./types";
import { RootState } from "../store";
import { ThunkDispatch } from "redux-thunk";

export const set_current_git_version = (
  payload: types.global_set_current_git_version_action["payload"]
): types.GlobalActionTypes => ({
  type: types.global_set_current_git_version,
  payload,
});

export const set_deployed_git_version = (
  payload: types.global_set_deployed_git_version_action["payload"]
): types.GlobalActionTypes => ({
  type: types.global_set_deployed_git_version,
  payload,
});

export const $fetch_if_update_is_available = (
  payload: types.global_set_current_git_version_action["payload"]
) => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, types.GlobalActionTypes>,
    getState: () => RootState
  ) => {
    dispatch(set_current_git_version(payload));

    const request = await fetch("/version.json");

    const data: {
      version: string;
      build_time: string;
    } = await request.json();

    dispatch(
      set_deployed_git_version({
        deployed_git_version: data.version,
        deployed_at: new Date(data.build_time),
      })
    );
  };
};

export const $reload = () => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, types.GlobalActionTypes>,
    getState: () => RootState
  ) => {
    const { di } = getState();

    di.AnalyticsService.send({
      category: "global",
      action: "reload",
    });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
};
