import { Playlist } from "@/types/playlist.type";
import { Track } from "../types/track.type";

const Albums: Record<string, Omit<Playlist, "tracks">> = {
  "eous-anthems": {
    playlist_name: "Eous Anthems",
    playlist_cover: "/covers/eous-anthems.png",
    playlist_id: "eous-anthems",
    playlist_type: "jukebox",
  },
  "casual-savior": {
    playlist_name: "Casual Savior",
    playlist_cover: "/covers/casual-savior.png",
    playlist_id: "casual-savior",
    playlist_type: "jukebox",
  },
  "daily-loop": {
    playlist_name: "Daily Loop",
    playlist_cover: "/covers/daily-loop.png",
    playlist_id: "daily-loop",
    playlist_type: "jukebox",
  },
  "shared-earbuds": {
    playlist_name: "Shared Earbuds",
    playlist_cover: "/covers/shared-earbuds.png",
    playlist_id: "shared-earbuds",
    playlist_type: "jukebox",
  },
  "random-play": {
    playlist_name: "Random Play",
    playlist_cover: "/covers/random-play.png",
    playlist_id: "random-play",
    playlist_type: "jukebox",
  },
  "virtual-revenge": {
    playlist_name: "Virtual Revenge",
    playlist_cover: "/covers/virtual-revenge.jpeg",
    playlist_id: "virtual-revenge",
    playlist_type: "jukebox",
  },
  ellen: {
    playlist_name: "Ellen Joe",
    playlist_cover: "/characters/ellen.png",
    playlist_id: "ellen-joe",
    playlist_type: "character",
  },
  burnice: {
    playlist_name: "Burnice",
    playlist_cover: "/characters/burnice.png",
    playlist_id: "burnice",
    playlist_type: "character",
  },
  caesar: {
    playlist_name: "Caesar",
    playlist_cover: "/characters/caesar.png",
    playlist_id: "caesar",
    playlist_type: "character",
  },
  "zhu-yuan": {
    playlist_name: "Zhu Yuan",
    playlist_cover: "/characters/zhu-yuan.png",
    playlist_id: "zhu-yuan",
    playlist_type: "character",
  },
};

const Artists = {
  bangblues: {
    artist: "Bangblues",
    artist_id: "bangblues",
  },
  catbang: {
    artist: "Catbang",
    artist_id: "catbang",
  },
};

const BurniceTracks: Track[] = [
  {
    title: "Burning Desires",
    title_id: "burning-desires",
    duration: 139,
    source: "/musics/burning-desires.mp3",
    created_at: new Date("2024-11-12"),
  },
].map((track) => ({
  ...track,
  ...Artists["bangblues"],
  ...Albums["burnice"],
}));

const CaesarTracks: Track[] = [
  {
    title: "Pinking",
    title_id: "pinking",
    duration: 187,
    source: "/musics/pinking.mp3",
    created_at: new Date("2024-11-14"),
  },
  {
    title: "Tour Inferno",
    title_id: "tour-inferno",
    duration: 266,
    source: "/musics/tour-inferno.mp3",
    created_at: new Date("2024-11-12"),
  },
].map((track) => ({
  ...track,
  ...Artists["bangblues"],
  ...Albums["caesar"],
}));

const ZhuYuanTracks: Track[] = [
  {
    title: "As the sugar cube floats, fleeting in time",
    title_id: "as-the-sugar-cube-floats-fleeting-in-time",
    duration: 211,
    created_at: new Date("2024-11-14"),
    source: "/musics/as-the-sugar-cube-floats-fleeting-in-time.mp3",
  },
  {
    title: "Hands Up",
    title_id: "hands-up",
    duration: 131,
    created_at: new Date("2024-11-14"),
    source: "/musics/hands-up.mp3",
  },
].map((track) => ({
  ...track,
  ...Artists["catbang"],
  ...Albums["zhu-yuan"],
}));

const EllenJoeTracks: Track[] = [
  {
    title: "Uniform Shark Tail Scissors",
    title_id: "uniform-shark-tail-scissors",
    duration: 159,
    created_at: new Date("2024-11-14"),
    source: "/musics/ellen-joe-uniform-shark-tail-scissors.mp3",
    ...Artists["catbang"],
    ...Albums["ellen"],
  },
  {
    title: "Shark's Gotta Bite",
    title_id: "sharks-gotta-bite",
    duration: 226,
    created_at: new Date("2024-11-14"),
    source: "/musics/ellen-joe--sharks-gotta-bite.mp3",
    ...Artists["catbang"],
    ...Albums["ellen"],
  },
];

const RandomPlayTracks: Track[] = [
  {
    title: "Outer Ring Dinner Etiquette",
    title_id: "outer-ring-dinner-etiquette",
    duration: 208,
    source: "/musics/outer-ring-dinner-etiquette.mp3",
    created_at: new Date("2024-10-10"),
  },
].map((track) => ({
  ...track,
  ...Artists["catbang"],
  ...Albums["random-play"],
}));

const CasualSaviorTracks: Track[] = [
  {
    title: "Dance of Death Acoustic Version",
    title_id: "dance-of-death-acoustic-version",
    duration: 158,
    source: "/musics/dance-of-death-acoustic-version.mp3",
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Cheesetopia: Feast Variation",
    title_id: "cheesetopia-feast-variation",
    duration: 62,
    source: "/musics/cheesetopia-feast-variation.mp3",
    created_at: new Date("2024-11-12"),
  },
].map((track) => ({
  ...track,
  ...Artists["catbang"],
  ...Albums["casual-savior"],
}));

const VirtualRevengeTracks: Track[] = [
  {
    title: "Outside Hand Headquarters",
    title_id: "outside-hand-headquarters",
    duration: 215,
    source: "/musics/outside-hand-headquarters.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "San-Z Studio Theme",
    title_id: "san-z-studio-theme",
    duration: 99,
    source: "/musics/san-z-studio-theme.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Simulated Battle Trial",
    title_id: "simulated-battle-trial",
    duration: 260,
    source: "/musics/simulated-battle-trial.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "The Mystery of Arpegio (TV Mode)",
    title_id: "the-mystery-of-arpegio-tv-mode",
    duration: 158,
    source: "/musics/the-mystery-of-arpegio--tv-mode.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Urban Stroll",
    title_id: "urban-stroll",
    duration: 176,
    source: "/musics/urban-stroll.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Virtual Revenge - Battle Theme 1",
    title_id: "virtual-revenge-battle-theme-1",
    duration: 179,
    source: "/musics/virtual-revenge--battle-theme-1.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Virtual Revenge - Battle Theme 2",
    title_id: "virtual-revenge-battle-theme-2",
    duration: 165,
    source: "/musics/virtual-revenge--battle-theme-2.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Virtual Revenge - Cut Scene",
    title_id: "virtual-revenge-cut-scene",
    duration: 43,
    source: "/musics/virtual-revenge--cut-scene.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Virtual Revenge - Story Beginning",
    title_id: "virtual-revenge-story-beginning",
    duration: 147,
    source: "/musics/virtual-revenge--story-beginning.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Virtual Revenge - Story Theme",
    title_id: "virtual-revenge-story-theme",
    duration: 146,
    source: "/musics/virtual-revenge--story-theme.mp3",
    created_at: new Date("2024-11-13"),
  },
  {
    title: "Virtual Revenge - Web Theme",
    title_id: "virtual-revenge-web-theme",
    duration: 129,
    source: "/musics/virtual-revenge--web-theme.mp3",
    created_at: new Date("2024-11-13"),
  },
].map((track) => ({
  ...track,
  ...Artists["catbang"],
  ...Albums["virtual-revenge"],
}));

export const tracks: Track[] = [
  {
    title: "Sword of Corruption",
    title_id: "sword-of-corruption",
    duration: 128,
    source: "/musics/sword-of-corruption.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "60% Daily",
    title_id: "60-percent-daily",
    artist: "Catbang",
    artist_id: "catbang",
    duration: 133,
    source: "/musics/daily-loop-60-daily.mp3",
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Ballet Labyrinth - Day",
    title_id: "ballet-labyrinth-day",
    duration: 318,
    source: "/musics/ballet-labyrinth--day.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Ballet Labyrinth - Night",
    title_id: "ballet-labyrinth-night",
    duration: 297,
    source: "/musics/ballet-labyrinth--night.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Bloom of Doom",
    title_id: "bloom-of-doom",
    duration: 174,
    source: "/musics/bloom-of-doom.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Camelia Week Battle",
    title_id: "camelia-week-battle",
    artist: "Bangblues",
    artist_id: "bangblues",
    duration: 231,
    source: "/musics/camelia-week-battle.mp3",
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Chaos Alpha",
    title_id: "chaos-alpha",
    duration: 267,
    source: "/musics/chaos-alpha.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Chaos Beta",
    title_id: "chaos-beta",
    duration: 319,
    source: "/musics/chaos-beta.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Chaos Delta",
    title_id: "chaos-delta",
    duration: 258,
    source: "/musics/chaos-delta.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Purchaseable Loyalty",
    title_id: "purchaseable-loyalty",
    duration: 139,
    source: "/musics/purchaseable-loyalty.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Chaos Gamma",
    title_id: "chaos-gamma",
    duration: 273,
    source: "/musics/chaos-gamma.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Dance of Death",
    title_id: "dance-of-death",
    duration: 152,
    source: "/musics/dance-of-death.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Derailed Order - Day",
    title_id: "derailed-order-day",
    duration: 343,
    source: "/musics/derailed-order--day.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Derailed Order - Night",
    title_id: "derailed-order-night",
    duration: 308,
    source: "/musics/derailed-order--night.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Dusk in the Wilderness",
    title_id: "dusk-in-the-wilderness",
    duration: 220,
    source: "/musics/dusk-in-the-wilderness.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Endless Construction - Day",
    title_id: "endless-construction-day",
    duration: 241,
    source: "/musics/endless-construction-day--day.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Endless Construction - Night",
    title_id: "endless-construction-night",
    duration: 313,
    source: "/musics/endless-construction-day--night.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Four Armed Sword",
    title_id: "four-armed-sword",
    duration: 141,
    source: "/musics/four-armed-sword.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Long Live the King",
    title_id: "long-live-the-king",
    duration: 144,
    source: "/musics/long-live-the-king.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Metal Chimera",
    title_id: "metal-chimera",
    duration: 195,
    source: "/musics/metal-chimera.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Soil of Disaster",
    title_id: "soil-of-disaster",
    duration: 186,
    source: "/musics/soil-of-disaster.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Young Lady Greta",
    title_id: "young-lady-greta",
    duration: 96,
    source: "/musics/young-lady-greta.mp3",
    ...Artists["catbang"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Warrior Friday",
    title_id: "warrior-friday",
    duration: 95,
    source: "/musics/warrior-friday.mp3",
    ...Artists["catbang"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Wind of the Wild",
    title_id: "wind-of-the-wild",
    duration: 252,
    source: "/musics/wind-of-the-wild.mp3",
    ...Artists["bangblues"],
    ...Albums["eous-anthems"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "60% Fantasy",
    title_id: "60-percent-fantasy",
    artist: "Catbang",
    artist_id: "catbang",
    duration: 143,
    source: "/musics/60-percent-fantasy.mp3",
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "60% Daily - Leisure",
    title_id: "60-percent-daily-leisure",
    artist: "Catbang",
    artist_id: "catbang",
    duration: 126,
    source: "/musics/60-percent-daily-leisure.mp3",
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "60% Fantasy - Serenity",
    title_id: "60-percent-fantasy-serenity",
    artist: "Catbang",
    artist_id: "catbang",
    duration: 143,
    source: "/musics/60-percent-fantasy-serenity.mp3",
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "60% Daily - Freedom",
    title_id: "60-percent-daily-freedom",
    duration: 143,
    source: "/musics/60-percent-daily-freedom.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "60% Fantasy - Passion",
    title_id: "60-percent-fantasy-passion",
    duration: 139,
    source: "/musics/60-percent-fantasy-passion.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Time to Play",
    title_id: "time-to-play",
    duration: 99,
    source: "/musics/time-to-play.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Up-Up-Down-Down-Left-Left-Right-Right",
    title_id: "up-up-down-down-left-left-right-right",
    duration: 147,
    source: "/musics/up-up-down-down-left-left-right-right.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Machine Hand Ground",
    title_id: "machine-hand-ground",
    duration: 79,
    source: "/musics/machine-hand-ground.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Surprise Box, for Good or Bad",
    title_id: "surprise-box-for-good-or-bad",
    duration: 134,
    source: "/musics/surprise-box-for-good-or-bad.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Turbo",
    title_id: "turbo",
    duration: 136,
    source: "/musics/turbo.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Tipsy Muse",
    title_id: "tipsy-muse",
    duration: 147,
    source: "/musics/tipsy-muse.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Action!",
    title_id: "action",
    duration: 148,
    source: "/musics/action.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "More Action!",
    title_id: "more-action",
    duration: 136,
    source: "/musics/more-action.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Vacation!",
    title_id: "vacation",
    duration: 133,
    source: "/musics/vacation.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Extended Vacation!",
    title_id: "extended-vacation",
    duration: 175,
    source: "/musics/extended-vacation.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "HIA",
    title_id: "hia",
    duration: 106,
    source: "/musics/hia.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "VR",
    title_id: "vr",
    duration: 310,
    source: "/musics/vr.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Yesterday's Prosperity",
    title_id: "yesterday-s-prosperity",
    duration: 138,
    source: "/musics/yesterday-s-prosperity.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Watch Your Head",
    title_id: "watch-your-head",
    duration: 119,
    source: "/musics/watch-your-head.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Limbo",
    title_id: "limbo",
    duration: 128,
    source: "/musics/limbo.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Guardians Of Order",
    title_id: "guardians-of-order",
    duration: 132,
    source: "/musics/guardians-of-order.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Stage for Rodents",
    title_id: "stage-for-rodents",
    duration: 152,
    source: "/musics/stage-for-rodents.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Blaze",
    title_id: "blaze",
    duration: 114,
    source: "/musics/blaze.mp3",
    ...Artists["catbang"],
    ...Albums["daily-loop"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Roger That",
    title_id: "roger-that",
    duration: 276,
    source: "/musics/roger-that.mp3",
    ...Artists["catbang"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Meow",
    title_id: "meow",
    duration: 234,
    source: "/musics/meow.mp3",
    ...Artists["bangblues"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Gears & A Smelting Pot",
    title_id: "gears-and-a-smelting-pot",
    duration: 163,
    source: "/musics/gears-and-a-smelting-pot.mp3",
    ...Artists["bangblues"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Fangs, Claws, and a Smile",
    title_id: "fangs-claws-and-a-smile",
    duration: 144,
    source: "/musics/fangs-claws-and-a-smile.mp3",
    ...Artists["bangblues"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Small but Great",
    title_id: "small-but-great",
    duration: 272,
    source: "/musics/small-but-great.mp3",
    ...Artists["bangblues"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "Real Bro Hans",
    title_id: "real-bro-hans",
    duration: 92,
    source: "/musics/real-bro-hans.mp3",
    ...Artists["catbang"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Victoria Style Service",
    title_id: "victoria-style-service",
    duration: 105,
    source: "/musics/victoria-style-service.mp3",
    ...Artists["catbang"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Shnnnn~",
    title_id: "shnnnn",
    duration: 165,
    source: "/musics/shnnnn~.mp3",
    ...Artists["bangblues"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Binary Leading Lady in Blue Breen",
    title_id: "binary-leading-lady",
    duration: 188,
    source: "/musics/binary-leading-lady-in-blue-breen.mp3",
    ...Artists["bangblues"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },
  {
    title: "Pride and Paranoia",
    title_id: "pride-and-paranoia",
    duration: 149,
    source: "/musics/pride-and-paranoia.mp3",
    ...Artists["bangblues"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },

  {
    title: "More Than Happy",
    title_id: "more-than-happy",
    duration: 169,
    source: "/musics/more-than-happy.mp3",
    ...Artists["catbang"],
    ...Albums["shared-earbuds"],
    created_at: new Date("2024-10-10"),
  },

  ...RandomPlayTracks,
  ...CasualSaviorTracks,
  ...VirtualRevengeTracks,
  ...EllenJoeTracks,
  ...BurniceTracks,
  ...CaesarTracks,
  ...ZhuYuanTracks,
];
