import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: "AIzaSyDTEvJTeudmMD-uz4Ulcd908lsOqAnBqU0",
  authDomain: "zenless-zone-zero-music.firebaseapp.com",
  projectId: "zenless-zone-zero-music",
  storageBucket: "zenless-zone-zero-music.firebasestorage.app",
  messagingSenderId: "113086279155",
  appId: "1:113086279155:web:0209704a6120ed65103686",
};

export class Firebase {
  private app;

  constructor() {
    this.app = initializeApp(config);
  }

  database() {
    return getFirestore(this.app);
  }

  getInstance() {
    return this.app;
  }

  getGoogleProvider() {
    return new GoogleAuthProvider();
  }

  auth() {
    return getAuth(this.app);
  }

  analytics() {
    return getAnalytics(this.app);
  }
}
